import React, { useEffect, useState } from "react";
import { BlocksUI, CustomBlockContext, IBlock } from "@blocksui/core";
import { SVG } from "./blocks";
import { BlocksUIFontAwesome } from "@blocksui/extension-font-awesome";
import { BlockHeroIcon } from "@blocksui/extension-hero-icons";
import { BlockMarkdown } from "@blocksui/extension-markdown";
import { BlockSyntaxHighlighter } from "@blocksui/extension-syntax-highlighter";

const App = () => {
  const [block, setBlock] = useState<IBlock>();
  useEffect(() => {
    (async () => {
      const result = await fetch("blocks/loadEntry");
      setBlock((await result.json()) as IBlock);
    })();
  }, []);

  if (!block) {
    return null;
  }

  return (
    <CustomBlockContext.Provider
      value={{
        renderers: {
          BlockSyntaxHighlighter,
          BlockMarkdown,
          BlocksUIFontAwesome,
          SVG,
          BlockHeroIcon,
        },
      }}
    >
      <BlocksUI block={block} baseUrl="/"></BlocksUI>
    </CustomBlockContext.Provider>
  );
};

export default App;
