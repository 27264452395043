import React from "react";
import { ReactComponent as BrandLogo } from '../img/brand_logo.svg';
import { ReactComponent as FullLogo } from '../img/logo.svg';
import { ReactComponent as Branch } from '../img/branch.svg';
import { ReactComponent as PullRequest } from '../img/pull_request.svg';
import { ReactComponent as CodeFile } from '../img/code-file.svg';
import { ReactComponent as AzureRepo } from '../img/azure-repo.svg';
import { ReactComponent as Tag } from '../img/tag.svg';
import { ReactComponent as Commit } from '../img/commit.svg';
import { ReactComponent as Push } from '../img/push.svg';
import { ReactComponent as AzureLoading } from '../img/azure_loading.svg';
import { ReactComponent as AzureSuccess } from '../img/azure_success.svg';
import { ReactComponent as AzureFailure } from '../img/azure_failure.svg';
import { ReactComponent as Spinner } from '../img/spinner.svg';

enum DocSVG {
    fulllogo,
    brandlogo,
    branch,
    pullrequest,
    codefile,
    azurerepo,
    tag,
    commit,
    push,
    azureloading,
    azuresuccess,
    azurefailure,
    spinner,
};

const DocSVGNames = {
    [DocSVG.fulllogo]: FullLogo,
    [DocSVG.brandlogo]: BrandLogo,
    [DocSVG.branch]: Branch,
    [DocSVG.pullrequest]: PullRequest,
    [DocSVG.codefile]: CodeFile,
    [DocSVG.azurerepo]: AzureRepo,
    [DocSVG.tag]: Tag,
    [DocSVG.commit]: Commit,
    [DocSVG.push]: Push,
    [DocSVG.azureloading]: AzureLoading,
    [DocSVG.azuresuccess]: AzureSuccess,
    [DocSVG.azurefailure]: AzureFailure,
    [DocSVG.spinner]: Spinner,
};

export const SVG: React.FC<{
    svg: DocSVG,
    childProps: any
}> = ({ svg, childProps: { className, ...props } }) => {
    const Icon = DocSVGNames[svg];

    return (<Icon className={`${className}`} {...props} />)
}
