import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {setupTwind} from "@blocksui/core";

setupTwind();

const rootElement = document.getElementById("root");

ReactDOM.render(<App/>, rootElement);

// serviceWorker();
